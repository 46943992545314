import React, { useState } from "react";
import CryptoJS from "crypto-js";
import Dropzone from "react-dropzone";
import { Buffer } from "buffer";
import { Container, Box, Button, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { saveAs } from "file-saver";
import ReactPlayer from "react-player";
import { create } from "ipfs-http-client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Footer from "./components/Footer";
import Header from "./components/Header";

import "./styles/App.css";

const projectId = "2DBM8M1VMImQ5vl4IQxYQzI8uKg";
const projectSecret = "f34bbedac4f01e7e79a9c99d04ba08cf";
const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

const client = create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  apiPath: "/api/v0",
  headers: {
    authorization: auth,
  },
});

const App = () => {
  const [error, setError] = useState(""); // State for error handling
  const [file, setFile] = useState(null);
  const [encryptKey, setEncryptKey] = useState("");
  const [encryptedData, setEncryptedData] = useState("");
  const [encryptedDataPasted, setEncryptedDataPasted] = useState("");
  const [decryptedData, setDecryptedData] = useState(null);
  const [fileFormat, setFileFormat] = useState("PNG");
  const [fileName, setFileName] = useState("");
  // const [anchorEl, setAnchorEl] = useState(null);
  const [ipfsHash, setIpfsHash] = useState("");
  const [themeMode, setThemeMode] = useState("light"); // Default theme mode is light
  // const [fileContent, setFileContent] = useState("");
  // const [setFileContent] = useState("");
  // const [decryptedContent, setDecryptedContent] = useState("");
  // const [setDecryptedContent] = useState("");
  // const [decodeSuccess, setDecodeSuccess] = useState(true); // Initially assuming decode is successful
  const [decodeSuccess] = useState(true); // Initially assuming decode is successful



  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none", // Change text case to lowercase
          },
        },
      },
    },
    palette: {
      mode: themeMode, // Theme mode controlled by state
      background: {
        default: themeMode === "light" ? "#fff" : "darkgray", // Custom background color for dark mode
      },
    },
  });

  const toggleThemeMode = () => {
    setThemeMode((prevMode) => (prevMode === "light" ? "dark" : "light")); // Toggle between light and dark mode
  };

  const handleFileFormatChange = (e) => {
    setFileFormat(e.target.value);
  };

  const handleImageUpload = async (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };

  const handleEncrypt = () => {
    if (!file || !encryptKey) {
      console.error("File or encryption key is missing");
      alert("Please provide a file and an encryption key");
      return;
    }

    const fileSizeLimit = 100 * 1024 * 1024; // 100 MB
    const supportedFileTypes = [
      "application/pdf",
      "text/plain",
      "image/png",
      "image/jpeg",
    ];

    // Check if the file type is supported
    if (!supportedFileTypes.includes(file.type)) {
      console.error("Unsupported file type");
      alert("Unsupported file type. Please upload a PDF, text, or image file.");
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      try {
        // Check if the file size exceeds the limit
        if (reader.result.byteLength > fileSizeLimit) {
          console.error("File size exceeds the limit");
          alert(
            "File size exceeds the 100 MB limit. Please upload a smaller file."
          );
          return;
        }

        // Convert the ArrayBuffer to a WordArray
        const wordArray = CryptoJS.lib.WordArray.create(reader.result);

        // Encrypt the WordArray
        const encrypted = CryptoJS.AES.encrypt(wordArray, encryptKey);
        if (!encrypted) {
          throw new Error("Encryption failed");
        }

        const encryptedBytes = encrypted.toString();
        setEncryptedData(encryptedBytes);
        alert("File encrypted successfully!");
      } catch (error) {
        console.error("Error during encryption:", error.message);
        alert("An error occurred while encrypting the file. Please try again.");
      }
    };

    reader.onerror = () => {
      console.error("Error reading the file:", reader.error);
      alert(
        "An error occurred while reading the file. Please ensure the file is not corrupted."
      );
    };

    reader.readAsArrayBuffer(file);
  };

  const handleDecryptKey = (e) => {
    setEncryptKey(e.target.value);
  };

  // Decrypt file logic using user-supplied decode key
  const handleDecrypt = () => {
    if (!encryptedDataPasted || !encryptKey) return;

    try {
      const decrypted = CryptoJS.AES.decrypt(encryptedDataPasted, encryptKey);
      const decryptedBytes = decrypted.toString(CryptoJS.enc.Latin1);

      // Create a Blob from decrypted data
      const bytes = new Uint8Array(decryptedBytes.length);
      for (let i = 0; i < decryptedBytes.length; i++) {
        bytes[i] = decryptedBytes.charCodeAt(i);
      }

      const blob = new Blob([bytes], {
        type: `image/${fileFormat.toLowerCase()}`,
      });

      setDecryptedData(URL.createObjectURL(blob));
    } catch (error) {
      console.error("Decryption failed. Ensure the key is correct.", error);
      alert("Decryption failed. Check your key or encrypted data.");
    }
  };



  const handleUploadToIPFS = async () => {
    if (!encryptedData) return;

    try {
      const added = await client.add(encryptedData);
      const url = `https://freeweb3.infura-ipfs.io/ipfs/${added.path}`;
      setIpfsHash(url);
      console.log("IPFS URL:", url);
    } catch (error) {
      console.log("Error uploading to IPFS:", error);
    }
  };

  const handleLoadEncryptedData = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const content = event.target.result;
        setEncryptedDataPasted(content); // Populate the textarea with file content
      };
      reader.onerror = (error) => {
        console.error("Failed to read file:", error);
        alert("Could not load the file. Please try again.");
      };
      reader.readAsText(file);
    }
  };

  
  const handleDownloadEncryptedData = () => {
    if (!encryptedData) return;

    // Get the current date and time for the filename
    // Replaces unwanted characters in the filename
    const dateTimePrefix = new Date().toISOString().replace(/[:.-]/g, "_");
    const fileName = `CID_${dateTimePrefix}.txt`;

    // Create a Blob with the encrypted data
    const blob = new Blob([encryptedData], { type: "text/plain" });

    // Use the file-saver library to save the file
    saveAs(blob, fileName);
  };

  // const handleDownloadDecryptedData = () => {
  //   if (!decryptedData) return;
  //   saveAs(decryptedData, `decrypted_image.${fileFormat.toLowerCase()}`);
  // };


  // const handleDownload = () => {
  //   if (!decryptedData) return;
  //   saveAs(decryptedData, `decrypted_image.${fileFormat.toLowerCase()}`);
  // };

  const handleDownloadDecryptedData = () => {
    if (!decryptedData) return;
  
    // Get the current date and time in a readable format
    const now = new Date();
    const formattedDate = now
      .toISOString()
      .replace(/[-:]/g, "") // Remove unwanted characters
      .replace("T", "_") // Replace 'T' with '_'
      .split(".")[0]; // Remove milliseconds
  
    // Construct the filename
    const fileName = `decoded_file_${formattedDate}.${fileFormat.toLowerCase()}`;
  
    // Trigger the download
    saveAs(decryptedData, fileName);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="lg"
        style={{
          backgroundColor: themeMode === "light" ? "snow" : "darkgray",
          minHeight: "100vh",
        }}
      >
        <Box>
          <Header themeMode={themeMode} toggleThemeMode={toggleThemeMode} />

          <Box
            margin="auto"
            padding="20px"
            marginTop="20px"
            bgcolor={theme.palette.mode === "light" ? "mintcream" : "silver"}
            sx={{
              border: "4pt solid gray",
              borderRadius: "8px",
            }}
          >
            <Box padding="20px" margin="20px" bgcolor="mintcream">
              <Typography variant="h4">Encrypt and Decrypt Files</Typography>

              <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                {" "}
                <Box width={{ xs: "100%", sm: "calc(50% - 10px)" }}>
                  {" "}
                  {/* File input */}
                  <input
                    id="fileInput"
                    type="file"
                    onChange={(e) => handleImageUpload(e.target.files)}
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="contained"
                    component="label"
                    style={{
                      backgroundColor: "royalblue",
                      color: "white",
                      border: "none",
                      padding: "16px 32px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "1rem",
                      marginTop: "10px",
                      width: "100%",
                    }}
                  >
                    Browse Images to Encrypt
                    <input
                      type="file"
                      onChange={(e) => handleImageUpload(e.target.files)}
                      style={{ display: "none" }}
                    />
                  </Button>
                  <br />
                  <br />
                  {fileName && <p>Selected File: {fileName}</p>}


                  {/* <input
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    style={{ display: "block", marginBottom: "10px" }}
                  /> */}
                  
                  
                  <Box width="calc(50% - 10px)">
                    <label htmlFor="fileFormat">Select File Format:</label>

                    <select
                      id="fileFormat"
                      value={fileFormat}
                      onChange={handleFileFormatChange}
                      style={{ width: "100%", marginBottom: "10px" }}
                    >
                      <option value="PNG">PNG</option>
                      <option value="JPG">JPG</option>
                      <option value="JPEG">JPEG</option>
                      <option value="GIF">GIF</option>
                    </select>
                  </Box>
                  <Box marginY="5px">
                    <Box marginY="40px" width="calc(70% - 10px)" mr="10px">
                      <label htmlFor="fileFormat">
                        Select Encrypt/Decode Key:
                      </label>

                      <input
                        type="text"
                        placeholder="Enter a key to use to encrypt the file data"
                        value={encryptKey}
                        onChange={(e) => {
                          const value = e.target.value;
                          setEncryptKey(value);

                          // Error handling: Check if the input is too short
                          if (value.length < 4) {
                            setError("The key must be at least 4 digits long.");
                          } else {
                            setError(""); // Clear error if valid
                          }
                        }}
                        style={{ width: "150%", marginBottom: "10px" }}
                        pattern="\d{4,}"  // Ensures numeric input with at least 4 digits
                        required
                      />
                      {error && <div style={{ color: "red", marginBottom: "10px" }}>{error}</div>}
                                
                    </Box>

                    {/* Dropzone */}
                    <Box
                      style={{
                        backgroundColor: theme.palette.primary.main,
                        padding: theme.spacing(2),
                      }}
                    >
                      <Dropzone onDrop={handleImageUpload}>
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            style={{
                              outline: "3px dashed black",
                              background: "lightgray",
                              padding: "40px",
                              textAlign: "center",
                            }}
                          >
                            <input {...getInputProps()} />
                            <p>Or drag and drop an image here.</p>
                          </div>
                        )}
                      </Dropzone>
                    </Box>
                    <br />
                  </Box>
                </Box>
              </Box>

              <Box width={{ xs: "100%", sm: "50%" }}>
                <button
                  onClick={handleEncrypt}
                  style={{
                    backgroundColor: "maroon",
                    color: "white",
                    border: "none",
                    padding: "16px 32px",
                    borderRadius: "8px",
                    cursor: "pointer",
                    fontSize: "1.2rem",

                    width: "100%", // Ensure button takes up entire width of its container
                  }}
                >
                  Encrypt the Image File
                </button>
                <br />
                <br />
              </Box>

              {/* <Button variant="contained" onClick={handleEncrypt}>
                Encrypt File
              </Button> */}

            </Box>

            <br />
          </Box>

          <br />

          <Box>

            <Box
              margin="auto"
              padding="10px"
              marginTop="10px"
              bgcolor={theme.palette.mode === "light" ? "mintcream" : "silver"}
              sx={{
                border: "4pt solid gray",
                borderRadius: "8px",
              }}
            >
              <h2 style={{ margin: "5px", padding: "10px" }}>
                Saving your encrypted data
              </h2>

              <Box padding="10px" margin="10px" bgcolor="mintcream">
                {encryptedData && (
                  <div>
                    <h3 style={{ margin: "2px", padding: "5px" }}>
                      Encrypted Data
                    </h3>
                    <div style={{ position: "relative", width: "100%" }}>
                      <textarea
                        value={encryptedData}
                        readOnly
                        style={{
                          width: "100%",
                          height: "150px",
                          marginBottom: "10px",
                        }}
                      />
                    </div>

                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                        marginRight: "10px",
                      }}
                    >
                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(encryptedData);
                        }}
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "48px",
                          height: "48px",
                          backgroundColor: "royalblue",
                          border: "2px solid royalblue",
                          borderRadius: "50%",
                          color: "white",
                          cursor: "pointer",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          transition: "transform 0.2s, background-color 0.2s",
                        }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.backgroundColor = "dodgerblue")
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.backgroundColor = "royalblue")
                        }
                        onFocus={(e) =>
                          (e.currentTarget.style.backgroundColor = "dodgerblue")
                        }
                        onBlur={(e) =>
                          (e.currentTarget.style.backgroundColor = "royalblue")
                        }
                      >
                        <FontAwesomeIcon icon={faCopy} size="lg" />
                      </button>
                      <span
                        style={{
                          visibility: "hidden",
                          width: "140px",
                          backgroundColor: "white",
                          color: "blue",
                          textAlign: "center",
                          borderRadius: "4px",
                          padding: "5px",
                          fontSize: "14px",
                          position: "absolute",
                          bottom: "60px",
                          left: "50%",
                          transform: "translateX(-50%)",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          zIndex: 1,
                          whiteSpace: "nowrap",
                        }}
                        className="tooltip-text"
                      >
                        Copy to Clipboard
                      </span>
                      <style>
                        {`
      .tooltip-text:hover,
      button:hover + .tooltip-text {
        visibility: visible;
      }
    `}
                      </style>
                    </div>

                    {/* Button to download the encrypted data as a text file */}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleDownloadEncryptedData}
                    >
                      Download Encrypted Data
                    </Button>

                    <br />
                    <br />
                    <Button
                      onClick={handleUploadToIPFS}
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: "royalblue",
                        color: "white",
                        border: "none",
                        padding: "16px 32px",
                        borderRadius: "8px",
                        cursor: "pointer",
                        fontSize: "1.2rem",
                        marginTop: "10px", // Add some margin to separate from the image
                        width: "50%",
                      }}
                    >
                      Upload to IPFS
                    </Button>
                    {ipfsHash && (
                      <div>
                        <h4>IPFS Hash:</h4>
                        <a
                          href={`${ipfsHash}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {ipfsHash}
                        </a>
                      </div>
                    )}
                  </div>
                )}
              </Box>
            </Box>
          </Box>

          <Box
            mt={4}
            textAlign="center"
            sx={{
              width: "90%",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Center the content */}
            <Typography variant="h5" gutterBottom>
              Demonstration Video
            </Typography>

            <ReactPlayer
              url="https://freeweb3.infura-ipfs.io/ipfs/QmYTraXQaPbXcv6vK2eaPrtz3icDJcfrjpHm59xbL2PPqL"
              controls={true}
              width="100%"
              config={{
                file: {
                  attributes: {
                    poster:
                      "https://freeweb3.infura-ipfs.io/ipfs/QmVTt4f3nyWAudnDwrFHATTSbDzsTpz9ZEReAotcLrk2Kf",
                  },
                },
              }}
            />
          </Box>
          <br />
          <hr />

          <Box
            margin="auto"
            padding="20px"
            marginTop="20px"
            bgcolor={theme.palette.mode === "light" ? "mintcream" : "silver"}
            sx={{
              border: "4pt solid gray",
              borderRadius: "8px",
            }}
          >
            <Box padding="20px" margin="20px" bgcolor="mintcream">
              <div>
                <Typography variant="h4">Decode the Encrypted Image</Typography>

                <Box marginY="20px">
                  {/* <input
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    style={{ display: "block", marginBottom: "10px" }}
                  /> */}
                  <input
                    type="file"
                    accept=".txt"
                    onChange={handleLoadEncryptedData}
                    style={{ display: "block", marginBottom: "10px" }}
                  />
                  {/* <input
                    type="text"
                    placeholder="Enter encryption key"
                    value={encryptKey}
                    onChange={(e) => setEncryptKey(e.target.value)}
                    style={{ width: "100%", marginBottom: "10px" }}
                  /> */}
                </Box>

                {/* <label htmlFor="fileFormat">Select File Format:</label>
                <select
                  id="fileFormat"
                  value={fileFormat}
                  onChange={handleFileFormatChange}
                  style={{ width: "25%", marginBottom: "10px" }}
                >
                  <option value="PNG">PNG</option>
                  <option value="JPG">JPG</option>
                  <option value="JPEG">JPEG</option>
                  <option value="GIF">GIF</option><input
  type="text"
  placeholder="Enter a key to use to encrypt the file data"
  value={encryptKey}
  onChange={(e) => {
    const value = e.target.value;
    setEncryptKey(value);

    // Error handling: Check if the input is too short
    if (value.length < 4) {
      setError("The key must be at least 4 digits long.");
    } else {
      setError(""); // Clear error if valid
    }
  }}
  style={{ width: "150%", marginBottom: "10px" }}
  pattern="\d{4,}"  // Ensures numeric input with at least 4 digits
  required
/>
                </select> */}

                <label htmlFor="decodeKey">Enter the Decode Key:</label>
                <input
                  type="text"
                  id="decodeKey"
                  placeholder="Enter the decode key used when encrypting the original file"
                  value={encryptKey}
                  onChange={handleDecryptKey}
                  style={{ width: "100%", marginBottom: "10px" }}
                />

                <Box marginY="20px">
                  <Typography variant="h6">
                    Select an encrypted text file or paste the encrypted data to decode in the box below:
                  </Typography>
                  <textarea
                    value={encryptedDataPasted}
                    onChange={(e) => setEncryptedDataPasted(e.target.value)}
                    rows={5}
                    style={{ width: "100%", marginBottom: "20px" }}
                  />
                  {/* <Button variant="contained" onClick={handleDecrypt}>
                    Decode raw encrypted file data
                  </Button> */}
                </Box>

                <button
                  onClick={handleDecrypt}
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    border: "none",
                    padding: "16px 32px",
                    borderRadius: "8px",
                    cursor: "pointer",
                    fontSize: "1.2rem",
                    width: "100%", // Ensure button takes up entire width
                    marginBottom: "10px", // Add some margin to separate from the image
                  }}
                >
                  Decode the Encypted Data
                </button>
                {!decodeSuccess && <p>Decode unsuccessful.</p>}
                {decryptedData && decodeSuccess && (
                  <div>
                    <Typography
                      variant="h3"
                      sx={{
                        color: "black",
                        fontSize: "20pt",
                        marginTop: "20pt",
                      }}
                    >
                      Image file decoding is successful if you see an image
                      below.
                    </Typography>
                    <hr />
                    <Typography
                      variant="h4"
                      sx={{
                        color: "maroon",
                        fontSize: "16pt",
                        marginBottom: "10pt",
                      }}
                    >
                      If you cannot see an image below, re-enter the correct
                      encrypted data and decode key.
                    </Typography>

                    <div style={{ width: "100%", overflow: "hidden" }}>
                      <img
                        src={decryptedData}
                        alt="Decoded"
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "block",
                        }}
                      />
                    </div>

                    {decryptedData && (
                      <Box marginY="20px">
                        {/* <Typography>Decrypted File:</Typography> */}
                        <Button
                          variant="contained"
                          onClick={handleDownloadDecryptedData}
                        >
                          Download Decoded File
                        </Button>
                      </Box>
                    )}
                    <button
                      onClick={handleDownloadDecryptedData}
                      style={{
                        backgroundColor: "royalblue",
                        color: "white",
                        border: "none",
                        padding: "16px 32px",
                        borderRadius: "8px",
                        cursor: "pointer",
                        fontSize: "1.2rem",
                        marginTop: "10px", // Add some margin to separate from the image
                        width: "100%", // Ensure button takes up entire width
                      }}
                    >
                      Download Image
                    </button>
                  </div>
                )}
              </div>
            </Box>
          </Box>
        </Box>
      </Container>

      <Footer themeMode={themeMode} />
    </ThemeProvider>
  );
};

export default App;
