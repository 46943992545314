import React from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  MenuItem,
} from "@mui/material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";

const Header = ({ themeMode, toggleThemeMode }) => {


  return (
    <AppBar position="static">
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {/* Logo Section */}
          <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
            <a
              href="https://lex.freeweb3.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://freeweb3.infura-ipfs.io/ipfs/QmQSqM3BbFrPtLqoPjiVoGLDVNkfxDUXTjZBjTKAYnT6VT"
                alt="Web3 Legal Engineering"
                style={{ width: "200px", marginRight: "20px" }}
              />
            </a>
          </Box>

          {/* Navigation Links */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" sx={{ mr: 2 }}>
              <a
                href="https://lexautomatico.com/auth/jwt/login?returnTo=%2Fdashboard%2Flegal-resources"
                target="blank"
                rel="noopener noreferrer"
                style={{ color: "lightcyan" }}
              >
                Dashboard
              </a>
            </Typography>
            <Typography variant="h6" sx={{ mr: 2 }}>
              <a
                href="https://artifacts.freeweb3.com"
                target="blank"
                rel="noopener noreferrer"
                style={{ color: "lightcyan" }}
              >
                Artifacts
              </a>
            </Typography>
            <Typography variant="h6">
              <a
                href="https://web3legaltools.com"
                target="blank"
                rel="noopener noreferrer"
                style={{ color: "lightcyan" }}
              >
                Tools
              </a>
            </Typography>

            {/* Theme Toggle */}
            <MenuItem onClick={toggleThemeMode}>
              {themeMode === "light" ? <DarkModeIcon /> : <LightModeIcon />}
            </MenuItem>


          </Box>
        </Box>
      </Toolbar>
    </AppBar>





  );
};

export default Header;
