import React from "react";
import { Container, Box } from "@mui/material";

const Footer = ({ themeMode }) => {
  return (
    <Container
      maxWidth="lg"
      style={{
        backgroundColor: themeMode === "light" ? "snow" : "darkgray",
        minHeight: "100vh",
      }}
    >
<Box
  mt={4}
  p={2}
  style={{
    backgroundColor: themeMode === "light" ? "darkgrey" : "dimgray",
  }}
>
<Box display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
    {[
      {
        src: "https://freeweb3.infura-ipfs.io/ipfs/QmQS6nmPYteogtgQWwofncybcndMegwjg74rY3RjZsJYJC",
        alt: "Evidencer.io",
        link: "https://evidencer.io",
        label: "Evidencer.io",
      },
      {
        src: "https://freeweb3.infura-ipfs.io/ipfs/QmXoDhswA8gxmo8BXraiKPbT5pSGus9RPdpGZKwm1LTDMD",
        alt: "Get Id",
        link: "https://id.evidencer.io",
        label: "Get Web3 Id",
      },
      {
        src: "https://freeweb3.infura-ipfs.io/ipfs/QmeSDp6DxNELxAgmoHjV2TWQ7KcrCiKqQMvCGNGTH8JTWk",
        alt: "Upload a Public File",
        link: "https://upload.freeweb3.com",
        label: "Upload a Public File",
      },
      {
        src: "https://freeweb3.infura-ipfs.io/ipfs/QmUHWwwMgbM6gkdfM2Pr6Rkgva3z5tD5vNcNqRZz9Y8PCG",
        alt: "Encrypt Image to IPFS",
        link: "https://image.encrypt.freeweb3.com/",
        label: "Encrypt Image to IPFS",
      },
      {
        src: "https://freeweb3.infura-ipfs.io/ipfs/QmeHoaD7keuX2cRv1owTMbdAgyDUyDtewFenpAo3hqhjds",
        alt: "Content Identifier to Chain",
        link: "https://cidtochain.freeweb3.com",
        label: "Content Identifier to Chain",
      },
      {
        src: "https://freeweb3.infura-ipfs.io/ipfs/QmRUpH83qyrsbhaeXph32N9uQZBsNva1BWZVWUT5bdMjZp",
        alt: "Create a Claim",
        link: "https://721.evidencer.freeweb3.com/",
        label: "Create a Claim",
      },
      {
        src: "https://freeweb3.infura-ipfs.io/ipfs/QmY9iNXGohSedLuC1hJ2f9mauGLSvQsT39Zg6D4yoFfArj",
        alt: "Evidencer Forms",
        link: "https://forms.evidencer.freeweb3.com/",
        label: "Evidencer Forms",
      },
    ].map((image, index) => (
        <Box key={index} textAlign="center" mx={2} position="relative">
          <a
            href={image.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "inline-block",
                "&:hover img": {
                  transform: "scale(1.2)",
                  transition: "transform 0.3s ease",
                },
                "&:hover span": {
                  visibility: "visible",
                  opacity: 1,
                  transition: "opacity 0.3s ease",
                },
              }}
            >
              <img
                src={image.src}
                alt={image.alt}
                style={{
                  width: "120px",
                  transition: "transform 0.3s ease",
                }}
              />
              <Box
                component="span"
                sx={{
                  visibility: "hidden",
                  opacity: 0,
                  position: "absolute",
                  bottom: "-30px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "#fff",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  fontSize: "12px",
                  whiteSpace: "nowrap",
                  zIndex: 2,
                  "@media (max-width: 600px)": {
                    bottom: "50%",
                    left: "50%",
                    transform: "translate(-50%, 50%)",
                    visibility: "visible",
                    opacity: 1,
                  },
                }}
              >
                {image.label}
              </Box>
            </Box>
          </a>
        </Box>
      ))}
    </Box>
  </Box>

      <footer className="footer">
        <div className="footer-content">
          <img
            src="https://freeweb3.infura-ipfs.io/ipfs/QmPgCAxMzpUjWaPMmmji5bhVoZD1w6P7RvPdY155wmF33N"
            alt="Evidencer Logo"
            className="footer-logo"
          />
          <span className="footer-text">
            ©2025 <span className="company-name">Web3 Legal Engineering</span>
          </span>
        </div>
        <nav className="footer-links">
          <a href="https:///terms.freeweb3.com" className="footer-link">
            Privacy Policy
          </a>
          <a href="https:///terms.freeweb3.com" className="footer-link">
            Terms of Service
          </a>
        </nav>
      </footer>


    </Container>
  );
};

export default Footer;
